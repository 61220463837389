/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = { fsIds: string[] };
export type ResponseType = { srcs: string[] };
export const Endpoint = "get-src-from-fsids";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

const isPublic = false;

export const RateLimitingConfig = {
  calls: 3,
  per: 2000,
};

export const paramsSchema = {"type":"object","properties":{"fsIds":{"type":"array","items":{"type":"string"}}},"required":["fsIds"],"$schema":"http://json-schema.org/draft-07/schema#"}