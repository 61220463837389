import React from "react";
import { Input } from "@hiyllo/ux/input";
import { Button } from "@hiyllo/ux/button";
import { styled } from "@hiyllo/ux/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import { InputOptions } from "./input-options";

const FooterContainer = styled("div", ({ $theme }) => ({
  padding: "5px",
  backgroundColor: $theme.midground1,
  display: "flex",
  flexDirection: "column",
}));

const FooterInput = styled("div", ({ $theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  width: "100%",
  padding: "5px 5px 5px 0px",
}));

export const Footer: React.FC<{
  sendReply: () => void;
  replyInputControl: any;
  isLoading: boolean;
}> = React.memo(({ sendReply, replyInputControl, isLoading }) => (
  <FooterContainer>
    <InputOptions />
    <FooterInput>
      <Input
        multiline
        placeholder='Reply or Type "/" for commands'
        onSubmit={sendReply}
        containerStyle={{
          backgroundColor: "transparent",
          fontSize: "15px",
        }}
        fullWidth={true}
        {...replyInputControl.inputProps}
      />
      <Button
        onClick={sendReply}
        isLoading={isLoading}
        label={<FontAwesomeIcon icon={faPaperPlane} />}
        style={{
          width: 5,
          height: 35,
          fontSize: 15,
        }}
      />
    </FooterInput>
  </FooterContainer>
));
