import React from "react";
import { useParams } from "react-router-dom";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";

import * as GetConversationBP from "../../../../blueprints/admin/conversations/get-conversation";
import * as ReplyToConversationBP from "../../../../blueprints/admin/conversations/reply-to-conversation";
import * as GetAgentNameBp from "../../../../blueprints/util/get-name-by-hiylloid";

import { client } from "../../../../singletons/moopsy-client";
import { styled } from "@hiyllo/ux/styled";
import { useInputControl } from "@hiyllo/ux/input";
import { useShowAlert } from "@hiyllo/ux/dialogs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { LoadingSpinnerView } from "@hiyllo/ux/loading-spinner";
import { useTheme } from "@hiyllo/ux/theme";
import { isInternalSender } from "../../../../utils";
import { Header } from "../../components/conversation-header";
import { Footer } from "../../components/conversation-footer";
import { MessageBubble } from "../../components/conversation-bubble";
import { Message } from "../../../../types/conversations/message";
import moment from "moment";

const Container = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  backgroundColor: $theme.background1,
  color: $theme.foreground,
}));

const MessagesContainer = styled("div", {
  flex: 1,
  padding: "0px 20px",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "15px",
});

const MessageGroup = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  gap: "10px",
  width: "100%",
});

const AvatarImg = styled("img", {
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  objectFit: "cover",
});

const MessageContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  width: "100%",
});

const SenderName = styled("div", ({ $theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  gap: "5px",
  fontSize: "16px",
  fontWeight: "bold",
  color: $theme.foreground,
}));

export const ConversationView: React.FC = React.memo(() => {
  const { uuid } = useParams<{ uuid: string }>();
  const showAlert = useShowAlert();
  const theme = useTheme();
  const [messages, setMessages] = React.useState<Message[]>([]);
  const [hoveredMessageId, setHoveredMessageId] = React.useState<string | null>(
    null
  );
  const [agentNames, setAgentNames] = React.useState<Record<string, string>>(
    {}
  );
  const [attachmentSrc, setAttachmentSrc] = React.useState<{} | null>(null);

  if (!uuid) {
    throw new Error("Conversation UUID is required");
  }

  const replyInputControl = useInputControl({});
  const conversationQuery = client.useQuery<GetConversationBP.Plug>(
    GetConversationBP,
    { uuid }
  );
  const replyMutation = client.useMutation<ReplyToConversationBP.Plug>(
    ReplyToConversationBP,
    { querySideEffects: [conversationQuery] }
  );
  const getAgentNameMutation =
    client.useMutation<GetAgentNameBp.Plug>(GetAgentNameBp);

  React.useEffect(() => {
    if (conversationQuery.data) {
      setMessages(conversationQuery.data.messages);
      console.log(JSON.stringify(conversationQuery.data, null, 2));
    }
  }, [conversationQuery.data]);

  React.useEffect(() => {
    if (messages.length === 0) return;

    const uniqueAgentIds = Array.from(
      new Set(
        messages
          .filter((message) => isInternalSender(message.sender))
          .map(
            (message) =>
              message.sender.type === "internal" && message.sender.hiylloID
          )
          .filter((id): id is string => Boolean(id))
      )
    );

    const fetchAgentNames = async () => {
      const agentNamePromises = uniqueAgentIds.map(async (hiylloID) => {
        try {
          const response = await getAgentNameMutation.call({
            hiylloID: hiylloID,
          });
          return { hiylloID, name: response.name };
        } catch {
          return { hiylloID, name: hiylloID };
        }
      });

      const results = await Promise.all(agentNamePromises);
      const namesMap = results.reduce((acc, { hiylloID, name }) => {
        acc[hiylloID] = name;
        return acc;
      }, {} as Record<string, string>);

      setAgentNames(namesMap);
    };

    fetchAgentNames();
  }, [messages]);

  const sendReply = React.useCallback(async () => {
    if (!replyInputControl.value.trim()) return;

    try {
      await replyMutation.call({
        conversationUUID: uuid,
        content: replyInputControl.value,
      });
    } catch (err) {
      showAlert({
        title: "Failed to Send Reply",
        message:
          err instanceof Error ? err.message : "An unknown error occurred",
      });
    }
  }, [replyInputControl, replyMutation, showAlert, uuid]);

  const messagesEndRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  if (conversationQuery.isLoading) {
    return <LoadingSpinnerView />;
  }

  if (conversationQuery.isError) {
    return (
      <EmptySplash
        icon={faExclamationCircle}
        label="Failed to Load Conversation"
        hint={conversationQuery.error.message}
      />
    );
  }

  const { conversation } = conversationQuery.data;

  const groupedMessages = messages.reduce((acc, message) => {
    const lastGroup = acc[acc.length - 1];
    if (
      lastGroup &&
      lastGroup.sender.type === message.sender.type &&
      (isInternalSender(lastGroup.sender) && isInternalSender(message.sender)
        ? lastGroup.sender.hiylloID === message.sender.hiylloID
        : !isInternalSender(lastGroup.sender) &&
          !isInternalSender(message.sender) &&
          lastGroup.sender.email === message.sender.email)
    ) {
      lastGroup.messages.push(message);
    } else {
      acc.push({ sender: message.sender, messages: [message] });
    }
    return acc;
  }, [] as { sender: any; messages: any[] }[]);

  return (
    <Container>
      <Header subject={conversation.subject} />
      <MessagesContainer>
        {groupedMessages.map((group, index) => (
          <MessageGroup key={index}>
            <AvatarImg
              src={
                isInternalSender(group.sender)
                  ? "https://i.pinimg.com/736x/c0/74/9b/c0749b7cc401421662ae901ec8f9f660.jpg"
                  : "https://archive.org/download/twitter-default-pfp/e.png"
              }
              alt={group.sender.hiylloID || group.sender.email}
            />
            <MessageContent>
              <SenderName>
                {isInternalSender(group.sender)
                  ? agentNames[group.sender.hiylloID] || group.sender.hiylloID
                  : group.sender.email}
                <span style={{ fontSize: "12px", verticalAlign: "middle" }}>
                  •
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    color: theme.foreground,
                    opacity: 0.7,
                  }}
                >
                  {moment(group.messages[0].date).format("MMM D, YYYY h:mm A")}
                </span>
                <span style={{ fontSize: "12px", verticalAlign: "middle" }}>
                  •
                </span>
                <p style={{ fontSize: "12px" }}>
                  via
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ padding: "0px 2px" }}
                  />
                  Email
                </p>
              </SenderName>

              {group.messages.map((message) => (
                <MessageBubble
                  key={message.uuid}
                  message={message}
                  isHovered={hoveredMessageId === message.uuid}
                  onHover={setHoveredMessageId}
                />
              ))}
            </MessageContent>
          </MessageGroup>
        ))}
        <div ref={messagesEndRef} />
      </MessagesContainer>
      <Footer
        sendReply={sendReply}
        replyInputControl={replyInputControl}
        isLoading={replyMutation.isLoading}
      />
    </Container>
  );
});
