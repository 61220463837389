import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Dashboard } from "./features/knowledge-base/views/admin/dashboard";
import { Home } from "./features/knowledge-base/views/user/home";
import { authExtension } from "./singletons/moopsy-client";
import { SSO } from "./views/sso";
import { Login } from "./views/login";
import { DefaultRolesEnum } from "./types/users/roles";
import { AuthExtensionStatus } from "@moopsyjs/react/main";
import { useIsInternal } from "./shared/hooks/use-is-internal";

export const App = React.memo(function App(): React.ReactElement {
  const authState = authExtension.useAuthState();
  const authStatus = authExtension.useAuthStatus();
  const isInternal = useIsInternal();

  // console.log(authState?.hiylloID);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="admin/dashboard/*"
          element={isInternal ? <Dashboard /> : <Home />}
        />
        <Route
          path="/iauth"
          element={
            authStatus === AuthExtensionStatus.loggedIn ? (
              <Navigate to="/" />
            ) : (
              <SSO />
            )
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
});
