import {
  faItalic,
  faBold,
  faSmile,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { hexToRGBA } from "../../../utils";
import { useTheme } from "@hiyllo/ux/theme";
import { motion, AnimatePresence } from "framer-motion";
import { faFileArrowUp, faUnderline } from "@fortawesome/pro-solid-svg-icons";
import { styled } from "@hiyllo/ux/styled";

const InputButton = styled("div", (props) => ({
  backgroundColor: props.$theme.midground1,
  color: props.$theme.foreground,
  border: "none",
  borderRadius: "5px",
  padding: "8px 10px",
  cursor: "pointer",
  transition: "background 0.2s",
  ":hover": {
    backgroundColor: hexToRGBA(props.$theme.midground2 || "", 0.6),
  },
}));

export const InputOptions: React.FC<{}> = React.memo(() => {
  const theme = useTheme();
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);

  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  // I will be extracting all fast reply logic into a separate component just not tonight
  // as I am tired and I want to sleep

  const fastReplies = [
    "Thank you for your message!",
    "I'll get back to you soon.",
    "Let me check on that.",
  ];

  const toggleIcon = isDropdownVisible ? faAngleUp : faAngleDown;

  const dropdownVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 10 },
  };

  return (
    <div
      style={{
        paddingLeft: "5px",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "5px",
        borderBottom: `1px solid ${theme.midground2}`,
        position: "relative",
      }}
    >
      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <label>
          <InputButton>
            <FontAwesomeIcon icon={faFileArrowUp} />
          </InputButton>
          <input
            type="file"
            style={{ display: "none" }} // Hide the actual input
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
              const files = evt.target.files; // Access the FileList
              if (files && files.length > 0) {
                console.log(files[0]); // Log the first file
              } else {
                console.log("No file selected");
              }
            }}
          />
        </label>
        <InputButton>
          <FontAwesomeIcon icon={faBold} />
        </InputButton>
        <InputButton>
          <FontAwesomeIcon icon={faItalic} />
        </InputButton>
        <InputButton>
          <FontAwesomeIcon icon={faUnderline} />
        </InputButton>
        <InputButton>
          <FontAwesomeIcon icon={faSmile} />
        </InputButton>
        <span
          style={{
            padding: "8px",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: hexToRGBA(theme.foreground ?? "", 0.3),
            borderRadius: "10px",
            cursor: "pointer",
            display: "inline-block",
            fontSize: "12px",
          }}
          onClick={toggleDropdown}
        >
          Fast Reply <FontAwesomeIcon icon={toggleIcon} />
        </span>
      </div>

      <AnimatePresence>
        {isDropdownVisible && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={dropdownVariants}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            style={{
              position: "absolute",
              bottom: "60px",
              left: "10px",
              width: "250px",
              background: `linear-gradient(135deg, ${theme.background2}, ${theme.background1})`,
              borderRadius: "12px",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
              border: `1px solid ${hexToRGBA(theme.foreground ?? "", 0.1)}`,
              padding: "10px",
              zIndex: 100,
            }}
          >
            {fastReplies.map((reply, index) => (
              <div
                key={index}
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  borderRadius: "8px",
                  transition: "background 0.2s",
                  color: theme.foreground,
                  marginBottom: index < fastReplies.length - 1 ? "8px" : "0",
                  background: hexToRGBA(theme.midground1 ?? "", 0.1),
                }}
                onClick={() => alert(`Selected: ${reply}`)}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.background = hexToRGBA(
                    theme.midground1 ?? "",
                    0.2
                  ))
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.background = hexToRGBA(
                    theme.midground1 ?? "",
                    0.1
                  ))
                }
              >
                {reply}
              </div>
            ))}
            <div
              style={{
                padding: "10px",
                cursor: "pointer",
                color: theme.foreground,
                textAlign: "center",
                borderRadius: "8px",
                transition: "background 0.2s",
              }}
              onClick={() => alert("Add Fast Reply clicked!")}
              onMouseEnter={(e) =>
                (e.currentTarget.style.background = hexToRGBA(
                  theme.colorSubtleAccent ?? "",
                  0.1
                ))
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.background = "transparent")
              }
            >
              + Add Fast Reply
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});
