import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { MessageOptions } from "../components/message-options";
import { hexToRGBA } from "../../../utils";
import { Message } from "../../../types/conversations/message";
import * as GetSrcFromFsId from "../../../blueprints/util/get-src-from-fsids";
import { client } from "../../../singletons/moopsy-client";
import { useAlert } from "../../../providers/alert-provider";
import { FileGallery, GalleryItem } from "../../../file-gallery";

const MessageBubbleContainer = styled("div", ({ $theme }) => ({
  display: "block",
  width: "100%",
  color: $theme.foreground,
  fontSize: "15px",
  lineHeight: 1.4,
  padding: "3px",
  position: "relative",
  ":hover": {
    backgroundColor: hexToRGBA($theme.midground1, 0.2),
  },
}));

export const MessageBubble: React.FC<{
  message: Message;
  isHovered: boolean;
  onHover: (id: string | null) => void;
}> = React.memo(({ message, isHovered, onHover }) => {
  const { pushAlert } = useAlert();
  const [attachmentSrcs, setAttachmentSrcs] = React.useState<string[]>([]);
  const getSrcFromFsIdMutation =
    client.useMutation<GetSrcFromFsId.Plug>(GetSrcFromFsId);

  React.useEffect(() => {
    if (message.attachmentUUIDs && message.attachmentUUIDs.length > 0) {
      getSrcFromFsIdMutation
        .call({ fsIds: message.attachmentUUIDs })
        .then((response) => {
          if (response && response.srcs) {
            setAttachmentSrcs(response.srcs); // Set the array of sources
          }
        })
        .catch((error) => {
          pushAlert("Error fetching attachment sources", "error");
        });
    }
  }, [getSrcFromFsIdMutation, message.attachmentUUIDs, pushAlert]);

  const galleryItems = React.useMemo(() => {
    return attachmentSrcs.length > 0 ? [{ src: attachmentSrcs[0] }] : [];
  }, [attachmentSrcs]);

  return (
    <MessageBubbleContainer
      onMouseEnter={() => onHover(message.uuid)}
      onMouseLeave={() => onHover(null)}
    >
      {message.content}
      {galleryItems.length > 0 && (
        <FileGallery files={galleryItems as [GalleryItem]} />
      )}
      {isHovered && <MessageOptions content={message.content} />}
    </MessageBubbleContainer>
  );
});
