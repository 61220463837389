import React from "react";
import { useNavigate } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { Button } from "@hiyllo/ux/button";
import { Input } from "@hiyllo/ux/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@hiyllo/ux/styled";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";

const HeaderContainer = styled("div", ({ $theme }) => ({
  padding: "20px",
  backgroundColor: $theme.background1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  zIndex: 10,
}));

export const Header: React.FC<{ subject: string }> = React.memo(
  ({ subject }) => {
    const navigate = useNavigate();

    return (
      <HeaderContainer>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Button
            isSecondary={true}
            onClick={() => navigate("/admin/dashboard/conversations")}
            label={<FontAwesomeIcon icon={faArrowLeft} />}
            style={{ width: 5, height: 35, fontSize: 15 }}
          />
          <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>{subject}</h1>
        </div>
        <div style={{ display: "flex", gap: 5 }}>
          <Input
            placeholder="Search Conversation"
            containerStyle={{ width: "300px" }}
          />
          <Button
            isSecondary={true}
            onClick={() => navigate("/admin/dashboard/conversations")}
            label={<FontAwesomeIcon icon={faAngleLeft} />}
            style={{ width: 10, height: 40, fontSize: 15 }}
          />
        </div>
      </HeaderContainer>
    );
  }
);
