// ArticleCard.tsx
import React from "react";
import { useTheme } from "@hiyllo/ux/theme";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper, faFilePen } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Article } from "../../../../../../types/knowledge-base/article";
import { styled } from "@hiyllo/ux/styled";

const Button = styled("button", {
  padding: "8px 16px",
  borderRadius: "20px",
  border: "none",
  cursor: "pointer",
  fontSize: "14px",
  transition: "background-color 0.3s ease",
});

export const ArticleCard = ({
  article,
  onClick,
  endUser,
}: {
  article: Article;
  onClick: () => void;
  endUser?: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const getFirstParagraph = (content: any): string => {
    if (!content || !content.descendants) return "No content available";

    // Find the first 'paragraph' type node
    const firstParagraph = content.descendants.find(
      (node: any) => node.type === "paragraph"
    );

    if (!firstParagraph || !firstParagraph.children)
      return "No content available";

    // Concatenate all 'text' values from the children
    return (
      firstParagraph.children
        .map((child: any) => child.text)
        .join(" ")
        .slice(0, 300) + "..."
    );
  };

  return (
    <motion.div
      layout="position"
      initial={{ opacity: 0, translateX: 20 }}
      animate={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, scale: 0.8 }}
      transition={{ duration: 0.1 }}
      style={{
        backgroundColor: endUser
          ? theme.colorScheme === "light"
            ? "rgba(255, 255, 255, 0.5)"
            : "rgba(0, 0, 0, 0.4)"
          : theme.background3,
        borderRadius: "10px",
        padding: "7.5px 12.5px",
        transition: "all 0.3s ease",
        minHeight: 30,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: `1px solid ${theme.midground1}`,
        width: "80%",
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 5,
            }}
          ></div>
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: theme.foreground,
              paddingBottom: 5,
            }}
          >
            {article.title}
          </div>
          <div
            style={{
              fontSize: "15px",
              color: theme.foreground,
              opacity: 0.6,
            }}
          >
            {getFirstParagraph(article.content)}
          </div>
        </div>
      </div>
      {endUser ? null : (
        <Button
          style={{
            backgroundColor: theme.background2,
            color: theme.foreground,
            marginLeft: 10,
          }}
          onClick={() => navigate("/admin/dashboard/article/" + article.uuid)}
        >
          <FontAwesomeIcon icon={faFilePen} /> Edit
        </Button>
      )}
    </motion.div>
  );
};
