import { faCopy, faLink, faThumbTack } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@hiyllo/ux/button";
import { styled } from "@hiyllo/ux/styled";
import React from "react";

interface MessageOptionsProps {
  content: string;
}

const MessageContainer = styled("div", ({ $theme }) => ({
  position: "absolute",
  top: "-35px",
  left: 0,
  width: "30%",
  padding: "5px",
  backgroundColor: $theme.midground1,
  color: $theme.foreground,
  display: "flex",
  alignItems: "center",
  borderRadius: "5px",
  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
  zIndex: 10,
  opacity: 0.4,
  gap: "5px",
  ":hover": {
    opacity: 1,
  },
}));

export const MessageOptions: React.FC<MessageOptionsProps> = React.memo(
  ({ content }) => {
    return (
      <MessageContainer>
        <Button
          onClick={() => navigator.clipboard.writeText(content)}
          label={<FontAwesomeIcon icon={faCopy} />}
          style={{
            borderRadius: 100,
            fontSize: 13,
            height: 30,
            width: 1,
          }}
        />
        <Button
          onClick={() => navigator.clipboard.writeText(content)}
          label={<FontAwesomeIcon icon={faLink} />}
          style={{
            borderRadius: 100,
            fontSize: 13,
            height: 30,
            width: 1,
          }}
        />
        <Button
          onClick={() => navigator.clipboard.writeText(content)}
          label={<FontAwesomeIcon icon={faThumbTack} />}
          style={{
            borderRadius: 100,
            fontSize: 13,
            height: 30,
            width: 1,
          }}
        />
      </MessageContainer>
    );
  }
);
