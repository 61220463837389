import React from "react";
import { styled } from "@hiyllo/ux/styled";

interface MultiSelectActionBarProps<T> {
  items: T[];
  selectedCount: number;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}

const ActionBar = styled("div", (props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "12px 20px",
  backgroundColor: props.$theme.background2,
  borderRadius: "8px",
  marginBottom: "16px",
  color: props.$theme.foreground,
}));

const DefaultLeftContent = styled("div", {
  fontSize: "14px",
  fontWeight: "bold",
});

export const MultiSelectActionBar = React.memo(
  <T extends {}>({
    items,
    selectedCount,
    leftContent,
    rightContent,
  }: MultiSelectActionBarProps<T>) => {
    return (
      <ActionBar>
        {leftContent || (
          <DefaultLeftContent>
            {selectedCount} items selected
          </DefaultLeftContent>
        )}
        <div style={{ display: "flex", gap: "12px" }}>{rightContent}</div>
      </ActionBar>
    );
  }
);
