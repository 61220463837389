/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {};
export type ResponseType = {
  priorities: {
    key: string;
    label: string;
  }[];
};
export const Endpoint = "admin/conversations/get-conversation-priorities";
export const Method = "GET";

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};
export const paramsSchema = {"type":"object","properties":{},"$schema":"http://json-schema.org/draft-07/schema#"}