/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { Conversation } from "../../../types/conversations/conversation";
import { Message } from "../../../types/conversations/message";

export type ParamsType = {
  uuid: string;
  limit?: number;
  beforeDate?: Date;
};
export type ResponseType = {
  conversation: Conversation;
  messages: Message[];
};
export const Endpoint = "admin/conversations/get-conversation";
export const Method = "POST";

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};
export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"},"limit":{"type":"number"},"beforeDate":{"type":"object","format":"date-time"}},"required":["uuid"],"$schema":"http://json-schema.org/draft-07/schema#"}