import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPen,
  faPencil,
  faPlus,
  faSearch,
  faTrash,
  faTrashCan,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { styled } from "@hiyllo/ux/styled";
import {
  Article,
  DocumentContentsV2,
} from "../../../../../types/knowledge-base/article";
import { ThemeType, useTheme } from "@hiyllo/ux/theme";
import NoContent from "../../../../../components/no-content";
import { motion } from "framer-motion";
import * as CreateArticleBP from "../../../../../blueprints/knowledge-base/article/create-article";
import * as UpdateArticleBP from "../../../../../blueprints/knowledge-base/article/update-article";
import * as UpdateArticlesBP from "../../../../../blueprints/knowledge-base/article/update-articles";
import * as DeleteArticlesBP from "../../../../../blueprints/knowledge-base/article/delete-articles";
import * as RetrieveAllArticlesBP from "../../../../../blueprints/knowledge-base/article/retrieve-all-articles";
import { authExtension } from "../../../../../singletons/moopsy-client";
import { client } from "../../../../../singletons/moopsy-client";
import { SectionLookup } from "../../../../../features/knowledge-base/components/section-lookup";
import { Section } from "../../../../../types/knowledge-base/section";
import { useParams, useNavigate } from "react-router-dom";
import { EditorComponent } from "../components/article/article-editor";
import { TableComponent } from "../components/article/table";
import { useAlert } from "../../../../../providers/alert-provider";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { useShowConfirm, useShowDialog } from "@hiyllo/ux/dialogs";
import { MultiSelectActionBar } from "../../../../../features/knowledge-base/components/multi-select-action-bar";
import { ColumnDefinition } from "../../../../../features/knowledge-base/views/admin/components/article/table";
import moment from "moment";
import { Button } from "@hiyllo/ux/button";

const Container = styled("div", {
  margin: "20px 20px",
});

const Header = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const SearchBar = styled("div", (props: { $theme: ThemeType }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: props.$theme.background3,
  borderRadius: "20px",
  padding: "8px 16px",
  width: "300px",
  color: props.$theme.foreground,
}));

const Options = styled("div", {
  display: "flex",
  gap: "8px",
});

export const ArticleView: React.FC = React.memo(() => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { uuid } = useParams<{ uuid: string }>();
  const { pushAlert } = useAlert();
  const showDialog = useShowDialog();
  const showConfirm = useShowConfirm();
  const authState = authExtension.useAuthState();
  const [articles, setArticles] = React.useState<Article[]>([]);
  const [selectedArticles, setSelectedArticles] = React.useState<Article[]>([]);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [editedTitle, setEditedTitle] = React.useState<string>("");
  const [initialTitle, setInitialTitle] = React.useState<string>("");
  const [editingArticle, setEditingArticle] = React.useState<Article | null>(
    null
  );
  const [isSectionLookupOpen, setIsSectionLookupOpen] =
    React.useState<boolean>(false);
  const [editedArticleContent, setEditedArticleContent] =
    React.useState<DocumentContentsV2 | null>(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isLoadingEditingArticle, setIsLoadingEditingArticle] =
    React.useState<boolean>(true);

  const getAllArticlesQuery = client.useQuery<RetrieveAllArticlesBP.Plug>(
    RetrieveAllArticlesBP,
    {}
  );

  const createArticleMutation = client.useMutation<CreateArticleBP.Plug>(
    CreateArticleBP,
    { querySideEffects: [getAllArticlesQuery] }
  );

  const updateArticleMutation = client.useMutation<UpdateArticleBP.Plug>(
    UpdateArticleBP,
    { querySideEffects: [getAllArticlesQuery] }
  );

  const updateArticlesMutation = client.useMutation<UpdateArticlesBP.Plug>(
    UpdateArticlesBP,
    { querySideEffects: [getAllArticlesQuery] }
  );

  const deleteSectionMutation = client.useMutation<DeleteArticlesBP.Plug>(
    DeleteArticlesBP,
    { querySideEffects: [getAllArticlesQuery] }
  );

  React.useEffect(() => {
    if (editingArticle) {
      setEditedTitle(editingArticle.title);
      setInitialTitle(editingArticle.title);
    }
  }, [editingArticle]);

  React.useEffect(() => {
    const fetchArticles = async () => {
      setIsLoading(true); // Start loading
      try {
        const allArticles = getAllArticlesQuery.data?.articles ?? [];
        setArticles(allArticles);
      } catch (error) {
        console.error("Failed to load articles", error);
      } finally {
        setIsLoading(false); // Stop loading regardless of success or error
      }
    };
    fetchArticles();
  }, [getAllArticlesQuery.data?.articles]);

  React.useEffect(() => {
    // Wait for articles data to be fully loaded before setting `editingArticle`
    if (!isLoading && articles.length > 0 && uuid) {
      const article = articles.find((article) => article.uuid === uuid);
      console.log("UUID:", uuid);
      console.log("Articles:", articles);
      console.log("Matched Article:", article);

      if (article) {
        setEditingArticle(article);
      }
    }
  }, [uuid, articles, isLoading]);

  React.useEffect(() => {
    if (editingArticle) {
      console.log("Editing Article", editingArticle);

      const draftContent = localStorage.getItem(`draft-${editingArticle.uuid}`);
      const parsedDraftContent = draftContent ? JSON.parse(draftContent) : null;

      // If there's a draft, check if it's identical to the article content
      const isDraftIdentical =
        parsedDraftContent &&
        JSON.stringify(parsedDraftContent) ===
          JSON.stringify(editingArticle.content);

      if (isDraftIdentical) {
        // Delete the draft if it's identical to the current article content
        localStorage.removeItem(`draft-${editingArticle.uuid}`);
        setEditedArticleContent(editingArticle.content);
        setIsLoadingEditingArticle(false);
      } else if (
        parsedDraftContent &&
        parsedDraftContent.descendants?.length > 0 &&
        JSON.stringify(parsedDraftContent) !==
          JSON.stringify(editedArticleContent)
      ) {
        // If the draft is different, prompt the user to load it
        showConfirm({
          title: "Load Draft",
          message:
            "A draft version of this article was found. Do you want to load it?",
          confirmLabel: "Yes",
        }).then((confirmed) => {
          if (confirmed) {
            setEditedArticleContent(parsedDraftContent);
          } else {
            setEditedArticleContent(editingArticle.content);
          }
          setIsLoadingEditingArticle(false);
        });
      } else {
        // No draft found or no differences, load the original content
        setEditedArticleContent(editingArticle.content);
        setIsLoadingEditingArticle(false);
      }
    }
  }, [editingArticle]);

  const filteredArticles = React.useMemo(
    () =>
      articles.filter((article) =>
        article.title.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [articles, searchTerm]
  );

  const columns: ColumnDefinition<Article>[] = React.useMemo(
    () => [
      {
        key: "title",
        label: "Title",
        sortable: true,
        defaultVisible: true,
        render: (article: Article) => (
          <div
            style={{ cursor: "pointer", color: theme.foreground }}
            onClick={() => setEditingArticle(article)}
          >
            {article.title}
          </div>
        ),
      },
      {
        key: "updatedAt",
        label: "Last Edited",
        sortable: true,
        defaultSortBy: "desc",
        defaultVisible: true,
        render: (article: Article) => (
          <span>{moment(article.updatedAt).format("MM/DD/YY, hh:mm A")}</span>
        ),
      },
      {
        key: "createdAt",
        label: "Created At",
        sortable: true,
        defaultVisible: false,
        render: (article: Article) => (
          <span>{moment(article.createdAt).format("MM/DD/YY, hh:mm A")}</span>
        ),
      },
      {
        key: "published",
        label: "Published",
        sortable: true,
        defaultVisible: true,
        render: (article: Article) => (article.published ? "Yes" : "No"),
      },
      {
        key: "authorName",
        label: "Author",
        sortable: false,
        defaultVisible: false,
        render: (article: Article) => <span>{article.authorName}</span>,
      },
    ],
    [theme, navigate]
  );

  const handleTitleChange = React.useCallback(
    (article?: Article) => {
      const articlesToProcess = selectedArticles.length
        ? selectedArticles
        : article
        ? [article]
        : [];
      if (!articlesToProcess.length) return;

      const uuids = articlesToProcess.map((a) => a.uuid);

      showDialog({
        title: "Rename Article",
        message: `Enter the new title for the selected articles`,
        requireValue: true,
        defaultValue:
          articlesToProcess.length === 1 ? articlesToProcess[0].title : "",
        onSubmit: async (newTitle) => {
          if (!newTitle) {
            pushAlert("Title cannot be empty", "error");
            return;
          }
          try {
            await updateArticlesMutation.call({
              uuids,
              title: newTitle,
            });

            setArticles((prevArticles) =>
              prevArticles.map((a) =>
                uuids.includes(a.uuid) ? { ...a, title: newTitle } : a
              )
            );

            setSelectedArticles([]);
          } catch (error) {
            pushAlert(
              "An error occurred while renaming the articles.",
              "error"
            );
          }
        },
      });
    },
    [pushAlert, selectedArticles, showDialog, updateArticlesMutation]
  );

  const handlePublishToggle = React.useCallback(
    async (article?: Article) => {
      const articlesToProcess = selectedArticles.length
        ? selectedArticles
        : article
        ? [article]
        : [];
      if (!articlesToProcess.length) return;

      const uuids = articlesToProcess.map((a) => a.uuid);
      const allPublished = articlesToProcess.every((a) => a.published);
      const action = allPublished ? "Un-Publish" : "Publish";

      showDialog({
        title: `${action} Articles`,
        message: `Are you sure you want to ${action} ${articlesToProcess.length} articles?`,
        onConfirm: async () => {
          try {
            await updateArticlesMutation.call({
              uuids,
              published: !allPublished,
            });

            setArticles((prevArticles) =>
              prevArticles.map((a) =>
                uuids.includes(a.uuid) ? { ...a, published: !allPublished } : a
              )
            );
            setSelectedArticles([]);
          } catch (error) {
            pushAlert(
              "An error occurred while updating the publish status.",
              "error"
            );
          }
        },
      });
    },
    [pushAlert, selectedArticles, showDialog, updateArticlesMutation]
  );

  const handleSectionSelect = async (section: Section) => {
    const uuids = selectedArticles.map((article) => article.uuid);

    try {
      // Update the articles with the new parent section UUID in batch
      await updateArticlesMutation.call({
        uuids, // Pass the array of UUIDs
        parentSectionUUID: section.uuid,
      });

      // Update the local state to reflect the changes
      setArticles((prevArticles) =>
        prevArticles.map((article) =>
          uuids.includes(article.uuid)
            ? { ...article, parentSectionUUID: section.uuid }
            : article
        )
      );

      // Clear the selection and close the section lookup modal
      setSelectedArticles([]);
      setIsSectionLookupOpen(false);
    } catch (error) {
      pushAlert("An error occurred while assigning the section.", "error");
    }
  };

  const handleDeleteArticle = React.useCallback(
    (article?: Article) => {
      const articlesToProcess = selectedArticles.length
        ? selectedArticles
        : article
        ? [article]
        : [];
      if (!articlesToProcess.length) return;

      const uuids = articlesToProcess.map((a) => a.uuid);

      showConfirm({
        title: "Delete Article",
        message: `Are you sure you want to delete ${articlesToProcess.length} articles?`,
      }).then(async (confirmed) => {
        if (confirmed) {
          try {
            await deleteSectionMutation.call({ uuids });

            setArticles((prevArticles) =>
              prevArticles.filter((a) => !uuids.includes(a.uuid))
            );
            setSelectedArticles([]);
          } catch (error) {
            pushAlert(
              "An error occurred while deleting the articles.",
              "error"
            );
          }
        }
      });
    },
    [deleteSectionMutation, selectedArticles, showConfirm]
  );

  const handleAssignSection = React.useCallback(
    (article?: Article) => {
      setSelectedArticles(article ? [article] : selectedArticles);

      setIsSectionLookupOpen(true);
    },
    [selectedArticles]
  );

  const handleSaveContent = React.useCallback(async () => {
    if (!editingArticle) return;

    // Retrieve content from local storage
    const draftContent = localStorage.getItem(`draft-${editingArticle.uuid}`);
    if (draftContent) {
      setIsSaving(true);
      try {
        await updateArticleMutation.call({
          uuid: editingArticle.uuid,
          content: JSON.parse(draftContent),
        });

        localStorage.removeItem(`draft-${editingArticle.uuid}`);
      } catch (error) {
        pushAlert("An error occurred while saving the article.", "error");
      } finally {
        setIsSaving(false);
      }
    } else {
      alert("No changes to save");
    }
  }, [editingArticle, pushAlert, updateArticleMutation]);

  const handleAddArticle = React.useCallback((): void => {
    createArticleMutation.call({
      title: "New Article",
      parentSectionUUID: "",
      authorUUID: authState?.uuid || "",
    });
  }, [authState?.uuid, createArticleMutation]);

  const handleCancelEdit = React.useCallback((): void => {
    setEditingArticle(null);
  }, []);

  return (
    <Container>
      {editingArticle ? (
        <>
          {editingArticle && editedArticleContent && (
            <EditorComponent
              article={editingArticle}
              content={editedArticleContent}
              onContentChange={setEditedArticleContent}
              updateArticleMutation={updateArticleMutation}
              onSave={handleSaveContent}
              onCancel={handleCancelEdit}
              isSaving={isSaving}
              isLoading={isLoadingEditingArticle}
            />
          )}
        </>
      ) : (
        <>
          {selectedArticles.length > 0 && (
            <div>
              {isSectionLookupOpen && (
                <SectionLookup
                  isModalOpen={isSectionLookupOpen}
                  onClose={() => setIsSectionLookupOpen(false)}
                  onSelectSection={handleSectionSelect}
                />
              )}
              <MultiSelectActionBar
                items={selectedArticles}
                selectedCount={selectedArticles.length}
                leftContent={
                  <span>{selectedArticles.length} articles selected</span>
                }
                rightContent={
                  <>
                    {selectedArticles.length > 0 &&
                      (() => {
                        const allPublished = selectedArticles.every(
                          (article) => article.published
                        );
                        const allUnpublished = selectedArticles.every(
                          (article) => !article.published
                        );

                        if (allPublished) {
                          return (
                            <Button
                              isSecondary={true}
                              label="Unpublish"
                              onClick={() => handlePublishToggle()}
                              style={{ height: "35px" }}
                            />
                          );
                        } else if (allUnpublished) {
                          return (
                            <Button
                              isSecondary={true}
                              label="Publish"
                              onClick={() => handlePublishToggle()}
                              style={{ height: "35px" }}
                            />
                          );
                        } else {
                          // Mixed state: no button displayed
                          return null;
                        }
                      })()}
                    <Button
                      isSecondary={true}
                      label="Assign"
                      onClick={() => handleAssignSection()}
                      style={{ height: "35px" }}
                    />
                    <Button
                      isSecondary={true}
                      label="Rename"
                      onClick={() => handleTitleChange()}
                      style={{ height: "35px" }}
                    />
                    <Button
                      isSecondary={true}
                      label="Delete"
                      onClick={() => handleDeleteArticle()}
                      style={{ height: "35px" }}
                    />
                  </>
                }
              />
            </div>
          )}
          <Header>
            <SearchBar $theme={theme}>
              <FontAwesomeIcon icon={faSearch} />
              <input
                type="text"
                placeholder="Search articles..."
                style={{
                  border: "none",
                  background: "transparent",
                  marginLeft: "8px",
                  fontSize: "16px",
                  width: "100%",
                  color: theme.foreground,
                  outline: "none",
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </SearchBar>
            <Options>
              <Button
                onClick={handleAddArticle}
                style={{
                  background: theme.colorSubtleAccent,
                  color: theme.foreground,
                  height: "35px",
                  borderRadius: "20px",
                }}
                label={
                  <>
                    <FontAwesomeIcon icon={faPlus} /> Add Article
                  </>
                }
              />
            </Options>
          </Header>
          <div style={{ marginTop: 20 }}>
            {isLoading ? (
              // Show loading spinner if loading
              <LoadingSpinner />
            ) : filteredArticles.length === 0 ? (
              // Show NoContent if no articles are found
              <NoContent
                plug={"No articles found, why don't you create one..."}
              />
            ) : (
              // Show TableComponent with articles if data is loaded and available
              <TableComponent<Article>
                data={filteredArticles}
                selectedItems={selectedArticles}
                setSelectedItems={setSelectedArticles}
                columns={columns}
                getRowId={(article) => article.uuid}
                searchTerm={searchTerm}
                rowActions={(article) => (
                  <>
                    <Button
                      label={
                        article.published ? (
                          <FontAwesomeIcon icon={faXmark} />
                        ) : (
                          <FontAwesomeIcon icon={faCheck} />
                        )
                      }
                      isSecondary={true}
                      onClick={() => handlePublishToggle(article)}
                      style={{
                        borderRadius: "5px 0px 0px 5px",
                      }}
                    />
                    <Button
                      label={<FontAwesomeIcon icon={faPlus} />}
                      isSecondary={true}
                      onClick={() => handleAssignSection(article)}
                      style={{
                        borderRadius: "0px",
                      }}
                    />
                    <Button
                      label={<FontAwesomeIcon icon={faPen} />}
                      isSecondary={true}
                      onClick={() => handleTitleChange(article)}
                      style={{
                        borderRadius: "0px",
                      }}
                    />
                    <Button
                      label={<FontAwesomeIcon icon={faTrashCan} />}
                      isSecondary={true}
                      onClick={() => handleDeleteArticle(article)}
                      style={{
                        borderRadius: "0px 5px 5px 0px",
                      }}
                    />
                  </>
                )}
              />
            )}
          </div>
        </>
      )}
    </Container>
  );
});
