import React, { useEffect, useMemo, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { useLocation } from "react-router-dom";

const getConstrainedRandomPercentage = (blobSize: number = 300): number => {
  const minPercentage = ((blobSize / window.innerWidth) * 100) / 2;
  const maxPercentage = 100 - minPercentage;
  return minPercentage + Math.random() * (maxPercentage - minPercentage);
};

const AnimatedBlob = React.memo(
  ({
    initialTop,
    initialLeft,
    animationEnabled,
  }: {
    initialTop: string;
    initialLeft: string;
    animationEnabled: boolean;
  }) => {
    const controls = useAnimation();

    // Keep track of current position to resume smoothly
    const currentTop = useRef(initialTop);
    const currentLeft = useRef(initialLeft);

    useEffect(() => {
      let isCancelled = false;

      const animateOnce = async () => {
        const randomTop = `${getConstrainedRandomPercentage()}%`;
        const randomLeft = `${getConstrainedRandomPercentage()}%`;

        await controls.start({
          top: randomTop,
          left: randomLeft,
          transition: {
            duration: 20,
            ease: "easeInOut",
          },
        });

        currentTop.current = randomTop;
        currentLeft.current = randomLeft;

        await new Promise((resolve) =>
          setTimeout(resolve, Math.random() * 2000 + 1000)
        );
      };

      const runAnimations = async () => {
        while (!isCancelled && animationEnabled) {
          await animateOnce();
        }
      };

      if (animationEnabled) {
        runAnimations();
      } else {
        controls.set({
          top: currentTop.current,
          left: currentLeft.current,
        });
      }

      const handleVisibilityChange = () => {
        if (document.hidden) {
          controls.stop();
        } else if (animationEnabled) {
          // If visible again and allowed to animate, restart animations
          runAnimations();
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        isCancelled = true;
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
        controls.stop();
      };
    }, [animationEnabled, controls]);

    return (
      <motion.div
        animate={controls}
        initial={{ top: initialTop, left: initialLeft }}
        style={{
          position: "absolute",
          width: "300px",
          height: "300px",
          borderRadius: "50%",
          background:
            "linear-gradient(135deg, rgb(254, 2, 254), rgb(254, 143, 1))",
          filter: "blur(80px)",
          zIndex: 0,
          opacity: 0.6,
        }}
      />
    );
  }
);

export const BackgroundBlobs = React.memo(() => {
  const location = useLocation();
  const isArticlePage = location.pathname.includes("/article/");
  const animationEnabled = !isArticlePage;

  const blobConfigurations = useMemo(() => {
    return Array.from({ length: 4 }, () => ({
      top: `${getConstrainedRandomPercentage()}%`,
      left: `${getConstrainedRandomPercentage()}%`,
    }));
  }, []);

  return (
    <div
      style={{
        overflow: "hidden",
        position: "absolute",
        height: "100vh",
        width: "100vw",
        top: 0,
        left: 0,
      }}
    >
      {blobConfigurations.map((config, index) => (
        <AnimatedBlob
          key={index}
          initialTop={config.top}
          initialLeft={config.left}
          animationEnabled={animationEnabled}
        />
      ))}
    </div>
  );
});
