import React, { useState, useEffect, useRef } from "react";
import { styled } from "@hiyllo/ux/styled";
import { Route, Routes, useNavigate } from "react-router-dom";
import { authExtension, client } from "../../../../singletons/moopsy-client";
import { Navbar } from "./components/navbar";
import * as RetrieveSectionBP from "../../../../blueprints/knowledge-base/section/retrieve-section";
import * as RetrieveAllSectionsBP from "../../../../blueprints/knowledge-base/section/retrieve-all-sections";
import * as RetrieveArticlesFromSectionBP from "../../../../blueprints/knowledge-base/article/retrieve-articles-from-section";
import { Section } from "../../../../types/knowledge-base/section";
import { Article } from "../../../../types/knowledge-base/article";
import { useMediaQuery } from "react-responsive";
import { useTheme } from "@hiyllo/ux/theme";
import { HomeContent } from "./views/home-content";
import { SectionContent } from "./views/section-content";
import { ArticleView } from "./views/article-content";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { Footer } from "../../../../shared/footer";
import { BackgroundBlobs } from "./components/background-blobs";

export const Container = styled("div", ({ $theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  color: $theme.foreground,
  background: $theme.background1,
  backdropFilter: "blur(15px)",
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 20,
  height: "calc(100% - 20px)",
  overflowX: "hidden",
}));

export const MainContainer = styled(
  "div",
  ({ $theme, isMobile }: { $theme: any; isMobile: boolean }) => ({
    flex: 1,
    color: $theme.foreground,
    zIndex: 1,
    overflowY: "auto",
  })
);

export const Home = React.memo(function Dashboard(): JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();
  const authState = authExtension.useAuthState();
  const [rootSections, setRootSections] = useState<Section[]>([]);
  const [articles, setArticles] = useState<Article[]>([]);
  const [currentParentUUID, setCurrentParentUUID] = useState<string | null>(
    null
  );
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const getAllSectionsQuery = client.useQuery<RetrieveAllSectionsBP.Plug>(
    RetrieveAllSectionsBP,
    {}
  );

  const getRootSectionQuery = client.useQuery<RetrieveSectionBP.Plug>(
    RetrieveSectionBP,
    { uuid: "root" }
  );

  const getSectionMutation =
    client.useMutation<RetrieveSectionBP.Plug>(RetrieveSectionBP);

  const getArticlesFromSection =
    client.useMutation<RetrieveArticlesFromSectionBP.Plug>(
      RetrieveArticlesFromSectionBP,
      {}
    );

  useEffect(() => {
    const fetchSectionsAndArticles = async () => {
      if (
        getAllSectionsQuery.isLoading ||
        getRootSectionQuery.isLoading ||
        !getAllSectionsQuery.data ||
        !getRootSectionQuery.data
      ) {
        return;
      }

      const allSections = getAllSectionsQuery.data.sections ?? [];

      const sectionsByUuid: { [uuid: string]: Section } = {};
      allSections.forEach((section) => {
        sectionsByUuid[section.uuid] = section;
      });

      let orderedSections: Section[] = [];

      const rootSection = getRootSectionQuery.data.section;
      if (rootSection) {
        orderedSections = rootSection.subSections
          .map((uuid: string) => sectionsByUuid[uuid])
          .filter(
            (section): section is Section =>
              !!section && section.subSections.length > 0
          );
      }

      setRootSections(orderedSections);

      try {
        const articlesResult = await getArticlesFromSection.call({
          sectionUuid: "root",
        });
        setArticles(articlesResult?.articles || []);
      } catch (error) {
        console.error("Failed to fetch articles:", error);
      }
    };

    fetchSectionsAndArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllSectionsQuery.data, getRootSectionQuery.data, getAllSectionsQuery.isLoading, getRootSectionQuery.isLoading]);

  useEffect(() => {
    const handleFocus = () => {
      if (authState === null) {
        window.location.reload();
      }
    };

    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [authState, navigate]);

  useEffect(() => {
    document.body.style.backgroundColor = theme.background1 || "";

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, [theme]);

  const handleSectionClick = (section: Section) => {
    setCurrentParentUUID(section.uuid);
    navigate(`/section/${section.uuid}`);
  };

  const handleArticleClick = (article: Article) => {
    navigate(`/article/${article.uuid}`);
  };

  return (
    <Container>
      <BackgroundBlobs />
      <Navbar />
      <MainContainer $theme={theme} isMobile={isMobile}>
        {getRootSectionQuery.isLoading || getAllSectionsQuery.isLoading ? (
          <LoadingSpinner />
        ) : (
          <Routes>
            <Route
              path="/"
              element={
                <HomeContent
                  rootSections={rootSections}
                  articles={articles}
                  onSectionClick={handleSectionClick}
                  onArticleClick={handleArticleClick}
                />
              }
            />
            <Route path="/section/:uuid" element={<SectionContent />} />
            <Route path="/article/:uuid" element={<ArticleView />} />
          </Routes>
        )}
      </MainContainer>
      <Footer />
    </Container>
  );
});
