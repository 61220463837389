import React from "react";
import { Section } from "../../../../../../types/knowledge-base/section";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTheme } from "@hiyllo/ux/theme";
import { ArticleCard } from "./article-card";
import * as DeleteSectionBP from "../../../../../../blueprints/knowledge-base/section/delete-section";
import * as UpdateSectionBP from "../../../../../../blueprints/knowledge-base/section/update-section";
import * as UpdateSectionOrderBP from "../../../../../../blueprints/knowledge-base/section/update-section-order";
import * as RetrieveAllSectionsBP from "../../../../../../blueprints/knowledge-base/section/retrieve-all-sections";
import * as RetrieveSectionBP from "../../../../../../blueprints/knowledge-base/section/retrieve-section";
import * as RetrieveArticlesFromSectionBP from "../../../../../../blueprints/knowledge-base/article/retrieve-articles-from-section";
import * as CreateSectionBP from "../../../../../../blueprints/knowledge-base/section/create-section";
import { client } from "../../../../../../singletons/moopsy-client";
import { Article } from "../../../../../../types/knowledge-base/article";
import NoContent from "../../../../../../components/no-content";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { NewSectionCard } from "./new-section-card";
import { SectionAdminCard } from "./section-admin-card";
import { EditingSectionCard } from "./editing-section-card";
import { useShowConfirm, useShowDialog } from "@hiyllo/ux/dialogs";
import { useAlert } from "../../../../../../providers/alert-provider";

interface SectionListProps {
  showNewSectionCard: boolean;
  setNewSectionCard: (value: boolean) => void;
  breadcrumbs: Section[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<Section[]>>;
  currentParentUUID: string | null;
  setCurrentParentUUID: React.Dispatch<React.SetStateAction<string | null>>;
  searchTerm: string;
}

export const SectionList: React.FC<SectionListProps> = React.memo((props) => {
  const theme = useTheme();
  const { pushAlert } = useAlert();
  const showDialog = useShowDialog();
  const [sections, setSections] = React.useState<Section[]>([]);
  const [sectionTitle, setSectionTitle] = React.useState("");
  const [sectionDescription, setSectionDescription] = React.useState("");
  const [selectedSectionUUID, setSelectedSectionUUID] = React.useState<
    string | null
  >(null);
  const [editingSection, setEditingSection] = React.useState<Section | null>(
    null
  );
  const [articles, setArticles] = React.useState<Article[] | null>(null);
  const [selectedIcon, setSelectedIcon] = React.useState<string>("");

  const getArticlesFromSection =
    client.useMutation<RetrieveArticlesFromSectionBP.Plug>(
      RetrieveArticlesFromSectionBP,
      {}
    );

  // Fetch all sections
  const getAllSectionsQuery = client.useQuery<RetrieveAllSectionsBP.Plug>(
    RetrieveAllSectionsBP,
    {}
  );

  // Fetch the root section
  const getRootSectionQuery = client.useQuery<RetrieveSectionBP.Plug>(
    RetrieveSectionBP,
    { uuid: "root" }
  );

  const deleteSectionMutation = client.useMutation<DeleteSectionBP.Plug>(
    DeleteSectionBP,
    { querySideEffects: [getAllSectionsQuery, getRootSectionQuery] }
  );

  const updateSectionMutation = client.useMutation<UpdateSectionBP.Plug>(
    UpdateSectionBP,
    { querySideEffects: [getAllSectionsQuery] }
  );

  const updateSectionOrderMutation =
    client.useMutation<UpdateSectionOrderBP.Plug>(UpdateSectionOrderBP, {
      querySideEffects: [getAllSectionsQuery, getRootSectionQuery],
    });

  const createSectionMutation =
    client.useMutation<CreateSectionBP.Plug>(CreateSectionBP);

  React.useEffect(() => {
    const fetchSections = async () => {
      const allSections = getAllSectionsQuery.data?.sections ?? [];

      const sectionsByUuid: { [uuid: string]: Section } = {};
      allSections.forEach((section) => {
        sectionsByUuid[section.uuid] = section;
      });

      let orderedSections: Section[] = [];

      if (props.currentParentUUID) {
        const parentSection = sectionsByUuid[props.currentParentUUID];
        if (parentSection) {
          orderedSections = parentSection.subSections
            .map((uuid: string) => sectionsByUuid[uuid])
            .filter(Boolean);
        }
      } else {
        const rootSection = getRootSectionQuery.data?.section;
        if (rootSection) {
          orderedSections = rootSection.subSections
            .map((uuid: string) => sectionsByUuid[uuid])
            .filter(Boolean);
        }
      }

      setSections(orderedSections);

      if (props.currentParentUUID) {
        // Fetch articles for the current section
        const articlesResult = await getArticlesFromSection.call({
          sectionUuid: props.currentParentUUID,
        });
        setArticles(articlesResult?.articles || null);
      } else {
        setArticles(null); // Clear articles if we're back at the root
      }
    };

    fetchSections();
  }, [
    getAllSectionsQuery.data?.sections,
    getRootSectionQuery.data?.section,
    props.currentParentUUID,
  ]);

  const filteredSections = sections.filter((section) =>
    section.title
      ? section.title.toLowerCase().includes(props.searchTerm.toLowerCase())
      : false
  );

  const getChildSectionCount = (sectionUUID: string) => {
    const allSections = getAllSectionsQuery.data?.sections ?? [];
    return allSections.filter(
      (section) => section.parentSectionUUID === sectionUUID
    ).length;
  };

  const handleOnDragEnd = React.useCallback(
    async (result: any) => {
      if (!result.destination) return;
      if (result.source.index === result.destination.index) return;

      const reorderedSections = Array.from(sections);
      const [removed] = reorderedSections.splice(result.source.index, 1);
      reorderedSections.splice(result.destination.index, 0, removed);

      setSections(reorderedSections);

      // Prepare the new order of subSections UUIDs
      const newSubSections = reorderedSections.map((section) => section.uuid);

      try {
        await updateSectionOrderMutation.call({
          parentUuid: props.currentParentUUID || "root",
          uuid: removed.uuid,
          subSections: newSubSections,
        });
      } catch (error) {
        console.error("Failed to update section order:", error);
        setSections(sections);
      }
    },
    [sections, updateSectionOrderMutation, props.currentParentUUID]
  );

  const handleCreateSection = async () => {
    if (!sectionTitle) {
      pushAlert("Please add a title to your section", "error");
      return;
    }

    const newSectionData = {
      title: sectionTitle,
      description: sectionDescription,
      parentSectionUUID: props.currentParentUUID || "root",
      icon: selectedIcon,
    };

    try {
      await createSectionMutation.call(newSectionData);

      // Refresh queries to get updated data
      getAllSectionsQuery.refresh();
      if (!props.currentParentUUID) {
        getRootSectionQuery.refresh();
      }

      props.setNewSectionCard(false);
      setSectionTitle("");
      setSectionDescription("");
      setSelectedIcon("");
      pushAlert("Created section", "success");
    } catch (error: any) {
      pushAlert(error.message, "error");
    }
  };

  const handleUpdateSection = React.useCallback(async () => {
    if (!editingSection) return;

    try {
      await updateSectionMutation.call({
        uuid: editingSection.uuid,
        title: editingSection.title,
        description: editingSection.description,
        icon: editingSection.icon as string,
      });

      setEditingSection(null);
      // Refresh queries to get updated data
      getAllSectionsQuery.refresh();
      pushAlert("Updated section", "success");
    } catch (error: any) {
      pushAlert(error.message, "error");
    }
  }, [editingSection, updateSectionMutation, getAllSectionsQuery, pushAlert]);

  const handleSectionClick = React.useCallback(
    (section: Section) => {
      if (editingSection) return;
      setSelectedSectionUUID(section.uuid);
      props.setCurrentParentUUID(section.uuid);
      props.setBreadcrumbs((prev) => [...prev, section]);
    },
    [editingSection, props.setCurrentParentUUID, props.setBreadcrumbs]
  );

  const handleDeleteSection = React.useCallback(
    async (uuid: string) => {
      showDialog({
        title: `Delete Section`,
        message: `Are you sure you want to delete this section?`,
        onConfirm: async () => {
          try {
            await deleteSectionMutation.call({ uuid });
            // Refresh queries to get updated data
            getAllSectionsQuery.refresh();
            if (!props.currentParentUUID) {
              getRootSectionQuery.refresh();
            }
            pushAlert("Deleted section", "success");
          } catch (error: any) {
            pushAlert(error.message, "error");
          }
        },
      });
    },
    [
      deleteSectionMutation,
      getAllSectionsQuery,
      getRootSectionQuery,
      props.currentParentUUID,
      pushAlert,
      showDialog,
    ]
  );

  const handlePublish = React.useCallback(
    async (section: Section) => {
      try {
        await updateSectionMutation.call({
          uuid: section.uuid,
          published: !section.published,
        });

        setEditingSection(null);
        pushAlert(
          section.published ? "Unpublished Section" : "Published Section",
          "success"
        );
      } catch (error: any) {
        pushAlert(error.message, "error");
      }
    },
    [pushAlert, updateSectionMutation]
  );

  return (
    <>
      {articles && articles.length > 0 && (
        <div>
          {articles.map((article) => (
            <ArticleCard
              key={article.uuid}
              article={article}
              onClick={() =>
                console.log(`Clicked on article: ${article.title}`)
              }
            />
          ))}
        </div>
      )}
      {props.showNewSectionCard && (
        <NewSectionCard
          sectionTitle={sectionTitle}
          sectionDescription={sectionDescription}
          selectedIcon={selectedIcon}
          setSelectedIcon={setSelectedIcon}
          setSectionTitle={setSectionTitle}
          setSectionDescription={setSectionDescription}
          handleCreateSection={handleCreateSection}
          setNewSectionCard={props.setNewSectionCard}
        />
      )}
      {getAllSectionsQuery.isLoading || getRootSectionQuery.isLoading ? (
        <div style={{ color: theme.foreground }}>
          <LoadingSpinner />
        </div>
      ) : filteredSections.length === 0 &&
        !props.showNewSectionCard &&
        (!articles || articles.length === 0) ? (
        <NoContent plug={"No sections found."} />
      ) : (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable-Sections">
            {(provided) => (
              <div
                style={{ marginTop: 0, color: theme.foreground }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {filteredSections.map((section, index) => (
                  <Draggable
                    key={section.uuid}
                    draggableId={section.uuid}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div
                          style={{
                            backgroundColor: theme.background3,
                            borderRadius: "8px",
                            marginBottom: "16px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleSectionClick(section)}
                        >
                          {editingSection &&
                          editingSection.uuid === section.uuid ? (
                            <EditingSectionCard
                              editingSection={editingSection}
                              setEditingSection={setEditingSection}
                              handleUpdateSection={handleUpdateSection}
                            />
                          ) : (
                            <SectionAdminCard
                              section={section}
                              handlePublish={handlePublish}
                              setEditingSection={setEditingSection}
                              handleDeleteSection={handleDeleteSection}
                              getChildSectionCount={getChildSectionCount}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
});
