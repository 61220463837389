/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = { conversationUUIDs: string[] };
export type ResponseType = void;
export const Endpoint = "admin/conversations/toggle-multi-conversations-status";
export const Method = "POST";

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};
export const paramsSchema = {"type":"object","properties":{"conversationUUIDs":{"type":"array","items":{"type":"string"}}},"required":["conversationUUIDs"],"$schema":"http://json-schema.org/draft-07/schema#"}