import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { Section } from "../../../../../types/knowledge-base/section";
import { useTheme } from "@hiyllo/ux/theme";
import { styled } from "@hiyllo/ux/styled";
import { useHeaderContent } from "../../../../../providers/header-content-provider";
import { SectionList } from "../components/section/section-list";
import { Breadcrumbs } from "../components/section/breadcrumbs";

const Container = styled("div", (props) => ({
  margin: "20px",
  color: props.$theme.foreground,
  overflowY: "auto",
  maxHeight: "97vh",
}));

const Header = styled("div", (props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "24px",
  position: "sticky",
  top: 0,
  backgroundColor: props.$theme.background1,
  zIndex: 60,
}));

const SearchBar = styled("div", (props) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "20px",
  backgroundColor: props.$theme.background3,
  padding: "8px 16px",
  width: "300px",
  zIndex: 10,
}));

const SectionInfo = styled("div", (props) => ({
  display: "flex",
  flexDirection: "column",
}));

const Button = styled("button", (props) => ({
  padding: "8px 16px",
  borderRadius: "20px",
  border: "none",
  cursor: "pointer",
  fontSize: "14px",
  transition: "background-color 0.3s ease",
}));

const SectionViewComponent = () => {
  const theme = useTheme();
  const { setHeaderContent } = useHeaderContent();
  const [searchTerm, setSearchTerm] = useState("");
  const [showNewSectionCard, setShowNewSectionCard] = useState(false);
  const [currentParentUUID, setCurrentParentUUID] = useState<string | null>(
    null
  );
  const [breadcrumbs, setBreadcrumbs] = useState<Section[]>([]);

  useEffect(() => {
    setHeaderContent(
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
          color: theme.foreground,
        }}
      >
        <SectionInfo>
          <h1
            style={{
              fontSize: "28px",
              fontWeight: "bold",
              color: theme.foreground,
              marginBottom: 0,
            }}
          >
            Manage your Sections
          </h1>
          <p
            style={{
              opacity: 0.8,
              fontSize: 14,
              marginTop: 0,
            }}
          >
            Make your content easier to find, rearrange your Sections or create
            new ones.
          </p>
        </SectionInfo>
      </div>
    );

    return () => setHeaderContent(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHeaderContent]);

  const handleAddSection = () => {
    setShowNewSectionCard(true);
  };

  const handleBreadcrumbClick = (index: number) => {
    const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
    setBreadcrumbs(newBreadcrumbs);
    setCurrentParentUUID(
      newBreadcrumbs[newBreadcrumbs.length - 1]?.uuid || null
    );
  };

  const handleBackClick = () => {
    if (breadcrumbs.length > 1) {
      const newBreadcrumbs = breadcrumbs.slice(0, -1);
      setBreadcrumbs(newBreadcrumbs);
      setCurrentParentUUID(newBreadcrumbs[newBreadcrumbs.length - 1].uuid);
    } else {
      setCurrentParentUUID(null);
      setBreadcrumbs([]);
    }
  };

  const changeNewSectionCard = (value: boolean) => {
    setShowNewSectionCard(value);
  };

  return (
    <Container>
      <Header>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            flexWrap: "wrap",
          }}
        >
          <SearchBar>
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              placeholder="Search sections..."
              style={{
                border: "none",
                background: "transparent",
                marginLeft: "8px",
                fontSize: "16px",
                width: "100%",
                outline: "none",
                color: theme.foreground,
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchBar>
          {breadcrumbs.length > 0 && (
            <Breadcrumbs
              breadcrumbs={breadcrumbs}
              onBreadcrumbClick={handleBreadcrumbClick}
              onBackClick={handleBackClick}
            />
          )}
        </div>
        <Button
          onClick={handleAddSection}
          style={{
            backgroundColor: theme.colorSubtleAccent,
            color: theme.foreground,
          }}
        >
          <FontAwesomeIcon icon={faPlus} /> Add Section
        </Button>
      </Header>
      <SectionList
        showNewSectionCard={showNewSectionCard}
        setNewSectionCard={(value) => changeNewSectionCard(value)}
        breadcrumbs={breadcrumbs}
        setBreadcrumbs={setBreadcrumbs}
        currentParentUUID={currentParentUUID}
        setCurrentParentUUID={setCurrentParentUUID}
        searchTerm={searchTerm}
      />
    </Container>
  );
};

export const SectionView = React.memo(SectionViewComponent);
