import React from "react";
import {
  faCheck,
  faCog,
  faEllipsisVertical,
  faSortDown,
  faSortUp,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@hiyllo/ux/styled";
import { ThemeType, useTheme } from "@hiyllo/ux/theme";
import { motion } from "framer-motion";
import { Button } from "@hiyllo/ux/button";

export type ColumnDefinition<T> = {
  key: keyof T;
  label: string;
  sortable?: boolean;
  defaultVisible?: boolean;
  defaultSortBy?: "asc" | "desc";
  render?: (item: T) => React.ReactNode;
};

interface TableComponentProps<T> {
  data: T[];
  selectedItems: T[];
  setSelectedItems: React.Dispatch<React.SetStateAction<T[]>>;
  columns: ColumnDefinition<T>[];
  searchTerm?: string;
  getRowId: (item: T) => string;
  rowActions?: (item: T) => React.ReactNode;
}

const TableContainer = styled("div", {
  width: "100%",
  borderRadius: "4px",
  overflowY: "auto",
  maxHeight: "90vh",
});

const TableBody = styled("div", {
  overflowY: "auto",
});

const CustomCheckbox = styled(
  "div",
  (props: { $checked: boolean; $theme: ThemeType }) => ({
    width: "20px",
    height: "20px",
    border: `2px solid ${props.$theme.colorSubtleAccent}`,
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: props.$checked
      ? props.$theme.colorSubtleAccent
      : "transparent",
    transition: "background-color 0.2s ease",
  })
);

const TableRow = styled("div", (props) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: props.$theme.background3,
  transition: "background-color 0.3s ease",
  borderBottom: `1px solid ${props.$theme.midground1}`,
  "&:last-child": {
    borderBottom: "none",
  },
  overflow: "hidden",
  maxHeight: 50,
  zIndex: 10,
}));

const TableHeader = styled(TableRow, (props) => ({
  fontWeight: "bold",
  backgroundColor: props.$theme.background2,
  position: "sticky",
  top: 0,
  zIndex: 20,
}));

const TableCell = styled("div", (props) => ({
  flex: 1,
  padding: "12px 16px",
  display: "flex",
  alignItems: "center",
  color: props.$theme.foreground,
  position: "relative", // important for relative positioning of menu
}));

const CheckboxCell = styled(TableCell, {
  flex: "0 0 0px",
});

function TableComponentInner<T extends { [key: string]: any }>(
  props: TableComponentProps<T>
) {
  const {
    data,
    selectedItems,
    setSelectedItems,
    columns,
    searchTerm = "",
    getRowId,
    rowActions,
  } = props;

  const theme = useTheme();

  const initialSortSettings = React.useMemo(() => {
    const columnWithDefaultSort = columns.find(
      (col) => col.defaultSortBy !== undefined
    );
    if (columnWithDefaultSort && columnWithDefaultSort.sortable) {
      return {
        column: columnWithDefaultSort.key,
        direction: columnWithDefaultSort.defaultSortBy as "asc" | "desc",
      };
    }
    return { column: "" as keyof T, direction: "" as "asc" | "desc" | "" };
  }, [columns]);

  const [sortColumn, setSortColumn] = React.useState<keyof T | "">(
    initialSortSettings.column
  );
  const [sortDirection, setSortDirection] = React.useState<"asc" | "desc" | "">(
    initialSortSettings.direction
  );
  const [sortClickCount, setSortClickCount] = React.useState<
    Record<string, number>
  >({});
  const [visibleColumns, setVisibleColumns] = React.useState<
    Record<string, boolean>
  >({});
  const [showColumnSettings, setShowColumnSettings] =
    React.useState<boolean>(false);
  const cogIconRef = React.useRef<HTMLDivElement>(null);
  const [cogPosition, setCogPosition] = React.useState<{
    top: number;
    left: number;
  }>({
    top: 0,
    left: 0,
  });
  const [openActionMenuId, setOpenActionMenuId] = React.useState<string | null>(
    null
  );
  const [actionMenuPosition, setActionMenuPosition] = React.useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  React.useEffect(() => {
    const initialVisibleColumns = columns.reduce((acc, column) => {
      acc[column.key as string] =
        column.defaultVisible !== undefined ? column.defaultVisible : true;
      return acc;
    }, {} as Record<string, boolean>);
    setVisibleColumns(initialVisibleColumns);
  }, [columns]);

  React.useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Use mousedown to determine if pointer started outside before click event fires
      if (
        (e.target as HTMLElement).closest(".action-menu") ||
        (e.target as HTMLElement).closest(".ellipsis-container")
      ) {
        return;
      }
      setOpenActionMenuId(null);
    };

    // Change from 'click' to 'mousedown' to avoid timing issues
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const updateCogPosition = React.useCallback(() => {
    if (cogIconRef.current) {
      const rect = cogIconRef.current.getBoundingClientRect();
      const panelWidth = 175;
      const viewportWidth = window.innerWidth;

      let left = rect.left;
      if (left + panelWidth > viewportWidth) {
        left = viewportWidth - panelWidth;
      }

      setCogPosition({
        top: rect.bottom + 20,
        left,
      });
    }
  }, []);

  const toggleColumnVisibility = React.useCallback((columnKey: string) => {
    setVisibleColumns((prevColumns) => ({
      ...prevColumns,
      [columnKey]: !prevColumns[columnKey],
    }));
  }, []);

  React.useEffect(() => {
    updateCogPosition();
    window.addEventListener("resize", updateCogPosition);
    return () => window.removeEventListener("resize", updateCogPosition);
  }, [updateCogPosition]);

  const toggleColumnSettings = () => {
    setShowColumnSettings(!showColumnSettings);
    updateCogPosition();
  };

  function isDate(value: unknown): value is Date {
    return value instanceof Date;
  }

  const sortedData = React.useMemo(() => {
    return [...data].sort((a, b) => {
      if (!sortColumn) return 0;
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      // Handle parsing date strings if necessary
      const parseDate = (value: any) => {
        if (isDate(value)) return value;
        if (typeof value === "string") {
          const date = new Date(value);
          return isNaN(date.getTime()) ? null : date;
        }
        return null;
      };

      const aDate = parseDate(aValue);
      const bDate = parseDate(bValue);

      if (aDate && bDate) {
        return sortDirection === "asc"
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      } else if (typeof aValue === "string" && typeof bValue === "string") {
        return sortDirection === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else if (typeof aValue === "boolean" && typeof bValue === "boolean") {
        return sortDirection === "asc"
          ? Number(aValue) - Number(bValue)
          : Number(bValue) - Number(aValue);
      } else if (typeof aValue === "number" && typeof bValue === "number") {
        return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
      }
      return 0;
    });
  }, [data, sortColumn, sortDirection]);

  const filteredData = React.useMemo(() => {
    if (!searchTerm) return sortedData;
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return sortedData.filter((item) => {
      return Object.values(item).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(lowerCaseSearchTerm);
        }
        return false;
      });
    });
  }, [sortedData, searchTerm]);

  const handleSort = React.useCallback(
    (column: keyof T): void => {
      setSortClickCount((prevCount) => {
        const newCount = ((prevCount[column as string] || 0) + 1) % 3;
        if (newCount === 1) {
          setSortColumn(column);
          setSortDirection("desc");
        } else if (newCount === 2) {
          setSortColumn("" as keyof T);
          setSortDirection("");
        } else {
          setSortColumn(column);
          setSortDirection("asc");
        }
        return {
          ...prevCount,
          [column as string]: newCount,
        };
      });
    },
    [setSortColumn, setSortDirection]
  );

  const handleSelectAll = React.useCallback((): void => {
    if (
      selectedItems.length === filteredData.length &&
      selectedItems.every(
        (item, index) => getRowId(item) === getRowId(filteredData[index])
      )
    ) {
      setSelectedItems([]);
    } else {
      setSelectedItems(filteredData);
    }
  }, [selectedItems, filteredData, setSelectedItems, getRowId]);

  const handleSelectItem = React.useCallback(
    (item: T): void => {
      const itemId = getRowId(item);
      setSelectedItems((prev) =>
        prev.some((i) => getRowId(i) === itemId)
          ? prev.filter((i) => getRowId(i) !== itemId)
          : [...prev, item]
      );
    },
    [setSelectedItems, getRowId]
  );

  const handleEllipsisClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: T) => {
      const itemId = getRowId(item);
      if (openActionMenuId === itemId) {
        setOpenActionMenuId(null);
      } else {
        setActionMenuPosition({ top: 0, left: 0 });
        setOpenActionMenuId(itemId);
      }
    },
    [getRowId, openActionMenuId]
  );

  return (
    <>
      <TableContainer>
        <TableHeader>
          <CheckboxCell>
            <CustomCheckbox
              $checked={
                selectedItems.length === filteredData.length &&
                selectedItems.every(
                  (item, index) =>
                    getRowId(item) === getRowId(filteredData[index])
                )
              }
              $theme={theme}
              onClick={handleSelectAll}
            >
              {selectedItems.length === filteredData.length && (
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: theme.background1 }}
                />
              )}
            </CustomCheckbox>
          </CheckboxCell>
          {columns.map((column) =>
            visibleColumns[column.key as string] ? (
              <TableCell
                key={column.key as string}
                onClick={() => column.sortable && handleSort(column.key)}
                style={{
                  cursor: column.sortable ? "pointer" : "default",
                }}
              >
                {column.label}
                {sortColumn === column.key && (
                  <FontAwesomeIcon
                    icon={sortDirection === "asc" ? faSortUp : faSortDown}
                    style={{ marginLeft: "8px" }}
                  />
                )}
              </TableCell>
            ) : null
          )}
          <TableCell style={{ flex: "0 0 auto" }}>
            <div ref={cogIconRef} onClick={toggleColumnSettings}>
              <FontAwesomeIcon icon={faCog} style={{ cursor: "pointer" }} />
            </div>
          </TableCell>
        </TableHeader>
        <TableBody>
          {filteredData.map((item) => {
            const isSelected = selectedItems.some(
              (i) => getRowId(i) === getRowId(item)
            );

            return (
              <TableRow key={getRowId(item)}>
                <CheckboxCell>
                  <CustomCheckbox
                    $checked={isSelected}
                    $theme={theme}
                    onClick={() => handleSelectItem(item)}
                  >
                    {isSelected && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ color: theme.background1 }}
                      />
                    )}
                  </CustomCheckbox>
                </CheckboxCell>

                {columns.map((column) =>
                  visibleColumns[column.key as string] ? (
                    <TableCell key={column.key as string}>
                      {column.render
                        ? column.render(item)
                        : String(item[column.key])}
                    </TableCell>
                  ) : null
                )}

                <TableCell
                  style={{
                    flex: "0 0 auto",
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <div className="ellipsis-container">
                    <Button
                      label={<FontAwesomeIcon icon={faEllipsisVertical} />}
                      onClick={() =>
                        /* Did this because of a Typescript error. idek */
                        handleEllipsisClick(
                          {
                            currentTarget: document.createElement("div"),
                            stopPropagation: () => {}, // Mock the event object
                          } as unknown as React.MouseEvent<HTMLDivElement>,
                          item
                        )
                      }
                      style={{
                        background: "transparent",
                        paddingRight: 6,
                        paddingLeft: 6,
                      }}
                    />
                  </div>

                  {openActionMenuId === getRowId(item) && rowActions ? (
                    <motion.div
                      className="action-menu"
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.5 }}
                      transition={{ duration: 0.2 }}
                      style={{
                        position: "absolute",
                        top: actionMenuPosition.top + 12,
                        right: 10,
                        borderRadius: "4px",
                        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                        color: theme.foreground,
                        display: "flex",
                      }}
                    >
                      {rowActions(item)}
                    </motion.div>
                  ) : null}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
      {showColumnSettings && (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 0.2 }}
          style={{
            position: "absolute",
            top: `${cogPosition.top}px`,
            left: `${cogPosition.left}px`,
            backgroundColor: theme.background2,
            padding: "16px",
            borderRadius: "8px",
            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
            zIndex: 1000,
            color: theme.foreground,
          }}
        >
          <div style={{ marginTop: 0, fontSize: "1.25em", fontWeight: "bold" }}>
            Select Columns
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {columns.map((column) => (
              <div
                key={column.key as string}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <CustomCheckbox
                  $checked={visibleColumns[column.key as string]}
                  $theme={theme}
                  onClick={() => toggleColumnVisibility(column.key as string)}
                >
                  {visibleColumns[column.key as string] && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: theme.background1 }}
                    />
                  )}
                </CustomCheckbox>
                <span style={{ marginLeft: "8px" }}>{column.label}</span>
              </div>
            ))}
          </div>
        </motion.div>
      )}
    </>
  );
}

export const TableComponent = React.memo(TableComponentInner) as <
  T extends { [key: string]: any }
>(
  props: TableComponentProps<T>
) => JSX.Element;
