import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ArticleView } from "../../knowledge-base/views/admin/views/article-view";
import { SectionView } from "../../knowledge-base/views/admin/views/section-view";
import { Sidebar } from "@hiyllo/ux/tokyo";
import {
  faArrowLeft,
  faFiles,
  faFolders,
} from "@fortawesome/pro-solid-svg-icons";
import { SupportAdminSidebar } from "../../admin/sidebar";

export const KnowledgeBaseAdminRouter = React.memo(
  function KnowledgeBaseAdminRouter(): JSX.Element {
    const location = useLocation();
    const navigate = useNavigate();

    return (
      <>
        <SupportAdminSidebar>
          <Sidebar.Header label="Knowledge Base" />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <div>
              <Sidebar.Button
                isActive={location.pathname.includes("articles")}
                icon={{ fa: faFiles }}
                onClick={() => {
                  navigate("articles");
                }}
                label="Articles"
              />
              <Sidebar.Button
                isActive={location.pathname.includes("sections")}
                icon={{ fa: faFolders }}
                onClick={() => {
                  navigate("sections");
                }}
                label="Sections"
              />
            </div>
            <Sidebar.Button
              isActive={false}
              icon={{ fa: faArrowLeft }}
              onClick={() => {
                navigate("../../../");
              }}
              label="Back to User View"
            />
          </div>
        </SupportAdminSidebar>
        <div style={{ width: 0, flexGrow: 1 }}>
          <Routes>
            <Route path="articles" element={<ArticleView />} />
            <Route path="sections/*" element={<SectionView />} />
            <Route
              path="article/:uuid"
              element={<ArticleView key="article-detail" />}
            />
            <Route path="*" element={<Navigate to="articles" replace />} />
          </Routes>
        </div>
      </>
    );
  }
);
